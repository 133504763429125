.frame-type-persons_regions,
.frame-type-persons_responsibilities,
.frame-type-persons_selected {
  .heading-lines {
    --min-size: 18;
    --max-size: 20;
  }

  .persons-card {
    @include motion-safe {
      transition: all 0.2s ease-in-out;
    }

    &__image {
      figure,
      &-region {
        grid-row: 1;
        grid-column: 1;
      }

      &-region {
        width: fit-content;
        --skew-overlay-background: #{__get($theme-colors, 'primary')};
        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(18px);
        }

        &:before {
          transform: translate(-0.4px, -0.1px);
        }

        span {
          background-color: __get($theme-colors, 'primary');
          font-weight: 700;
        }
      }
    }

    &__body {
      &-title {
        --min-size: 22;
        --max-size: 26;
        --headings-margin: #{$grid-gutter-width * 0.25};
      }

      &-categories {
        --bs-body-font-size: #{px-to-rem(18px)};

        line-height: 1.3;
        font-weight: 500;
        color: #{$grey-900};
        --margin: #{$grid-gutter-width * 0.5};

        margin-bottom: var(--margin);

        @include media-breakpoint-up(xl) {
          --margin: #{$grid-gutter-width * 0.75};
        }
      }

      &-description {
        --bs-body-font-size: #{px-to-rem(18px)};
        --margin: #{$grid-gutter-width * 0.5};

        margin-bottom: var(--margin);

        @include media-breakpoint-up(xl) {
          --margin: #{$grid-gutter-width * 0.75};
        }
      }

      &-email {
        font-size: px-to-rem(18px);
        line-height: 1.5;
        word-break: break-word;
      }

      > :last-child {
        --margin: 0;
      }

      &-toggle {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .dialog {
    h2 {
      --min-size: 30;
      --max-size: 44;
    }

    .bodytext {
      h3 {
        --min-size: 20;
        --max-size: 24;
        --headings-line-height: 1.2;
      }
    }

    .persons-card__body-categories {
      @include media-breakpoint-up(xl) {
        --bs-body-font-size: #{px-to-rem(22px)};
      }
    }
  }
}
