body {
  position: relative;

  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    inset: 0;
    background: #000;

    @include media-breakpoint-up(xl) {
      background: __get($theme-colors, 'white');
    }

    z-index: -1;

    @include motion-safe {
      transition: opacity 0.25s ease-in-out;
    }
  }

  &.tx-supi__overlay {
    --supi-overlay-background: rgba(0, 0, 0, 0.25);
    --supi-overlay-z-index: 1002;

    overflow: hidden;

    &:before {
      display: none;
    }
  }

  &.nav-open {
    &-mobile {
      overflow: hidden;

      &:before {
        opacity: 0.2;
        z-index: 1000;
      }
    }

    @include media-breakpoint-up(xl) {
      &:before {
        opacity: 0.8;
        z-index: 1000;
      }
    }
  }
}
